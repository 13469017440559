<template>
    <div v-if="device">
        <div class="container repair-container">

            <!-- Filter -->
            <div class="row">
                <div class="col-md-12">
                    <label class="mt-2">Select all problems that the customer has alerted you to</label>
                </div>
            </div>

            <!-- Problems List -->
            <ul class="list-group selection-list mb-4">
                <li class="list-group-item" v-for="problem in filteredProblems" :key="problem.id" :class="{'active': problemActive(problem)}">
                    <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input" :id="problem.code" :checked="problemActive(problem)" @click="toggleConfirmedProblem(problem)">
                        <label class="custom-control-label mb-0" :for="problem.code">{{ problem.title }}</label>
                    </label>
                </li>
            </ul>

            <!-- Footer -->
            <fixed-footer
                    :class="{'active': footerActive}"
                    @nextButtonClicked="sendConfirmedProblems({deviceId: deviceId, jobId: jobId})"
                    :nextLabel="this.nextRepairStep($route.name).title"
                    :nextRoute="this.nextRepairStep($route.name).routeName"
                    :prevRoute="this.prevRepairStep($route.name).routeName">
            </fixed-footer>

        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {

        props:["jobId", "deviceId"],

        data() {
            return {
                selectedProblems: null
            }
        },

        mounted() {
            this.setCurrentStep({
                jobId: this.jobId,
                deviceId: this.deviceId,
                currentStep: this.$route.name,
            });
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            });
            this.loadAddedConfirmedProblems({
                jobId: this.jobId,
                deviceId: this.deviceId
            });
            this.loadAllProblems();
        },

        computed: {
            ...mapGetters([
                "confirmedProblems",
                "device",
                "isJobManager",
                "problems",
                "nextRepairStep",
                "prevRepairStep",
            ]),

            /** Filter the list to hide in warranty repairs */
            filteredProblems() {
                const iwString = "-iw";

                return this.problems.filter((el) => {
                    return (!el.code.includes(iwString))
                });
            },

            /** Check if footer should be active */
            footerActive() {
                if (this.confirmedProblems.length > 0 || this.isJobManager) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions([
                "addConfirmedProblem",
                "loadAddedConfirmedProblems",
                "loadAllProblems",
                "loadDeviceForJob",
                "removeConfirmedProblem",
                "setCurrentStep",
                "sendConfirmedProblems"
            ]),

            /** Highlight any active problems */
            problemActive(problem) {
                let arr = this.confirmedProblems;
                return arr.some(el => el.code === problem.code);
            },

            /** Add and remove confirmed problems */
            toggleConfirmedProblem(problem) {
                let arr = this.confirmedProblems;

                if (arr.some(el => el.code === problem.code)) {
                    this.removeConfirmedProblem({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                        problemId: problem.id
                    });
                } else {
                    this.addConfirmedProblem({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                        problemId: problem.id
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>